import { motion } from "framer-motion";

const AboutBenny = () => {
  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="about"
    >
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-xl-5 col-lg-5 col-sm-12">
            <img
              src={require("../assets/benny-chavez-profile.webp")}
              alt="Benny Chavez"
            />
          </div>
          <div className="col-xl-6 col-lg-6 col-sm-12">
            <h2>MEET BENNY CHAVEZ</h2>
            <p>
              Benny Chavez discovered the benefits of hard work and the joy of
              customer satisfaction early in life, working at his father’s auto
              repair shop at age fourteen. At age nineteen, he saved enough
              money to buy ten acres of real estate in Joshua Tree. Though this
              investment did not result in monetary gain, it sparked an interest
              in real estate that has blossomed into a successful career.
            </p>
            <p>
              Chavez honed his skills for a dozen years as a real estate
              investment consultant before being appointed Branch Manager of a
              large brokerage in Southern California. Over four years, under his
              leadership, this branch of a global real estate brand grew to one
              of the top five branches in its division.
            </p>
            <p>
              Today he works full-time in a boutique firm as a real estate
              investment consultant for clients and as an agent adviser, helping
              agents improve their business and negotiating skills.{" "}
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default AboutBenny;
