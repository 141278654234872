/* COMPONENTS */
import Banner from "../components/banner";
import AboutBenny from "../components/about";
import FeaturedListings from "../components/featured-listings";
import Services from "../components/services";
import Testimonials from "../components/testimonials";
import InstagramWall from "../components/instagram-wall";
import Blogs from "../components/blogs";
import Footer from "../layout/footer";

const Home = () => {
    return (
      <>
        <section className="homepage">
          {/* BANNER */}
          <Banner />

          {/* ABOUT BENNY CHAVEZ */}
          <AboutBenny />

          {/* FEATURED LISTINGS */}
          <FeaturedListings />

          {/* SERVICES */}
          <Services />

          {/* TESTIMONIALS */}
          <Testimonials />

          {/* INSTAGRAM WALL */}
          <InstagramWall />

          {/* BLOGS */}
          <Blogs />
        </section>

        <Footer />
      </>
    );
};

export default Home;