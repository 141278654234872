/* eslint-disable array-callback-return */
/* eslint-disable no-useless-concat */
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from "../../../assets/amberwood-placeholder-img.webp";

/* ICON IMPORTS */
import { IoIosBed } from "react-icons/io";
import { FaBath } from "react-icons/fa";
import CameraIcon from "@iconscout/react-unicons/icons/uil-camera";

const ListingListView = (props) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });

  const unitFormatter = new Intl.NumberFormat("en-US", {
    notation: "standard",
    maximumFractionDigits: 0,
  });

  return (
    <Link
      to={`/property-detail/${props?.mlsNumber}/${props?.mlsKey}`}
      style={{ textDecoration: "none", color: "#000" }}
    >
      <motion.div className="card listing-list" layoutId={props.streetName}>
        <Card.Body className="listing-list-body">
          <div className="property-images">
            <Swiper
              modules={[Scrollbar, Navigation, Pagination]}
              navigation={true}
              slidesPerView={1}
              spaceBetween={30}
            >
              {props?.imgList?.map((element, key) => {
                if (
                  element?.MediaURL?.includes(
                    "https://api-trestle.corelogic.com/trestle/Media/CRMLS/Property/PHOTO-jpeg/" +
                      props?.data?.ListingKey +
                      "/1/"
                  )
                ) {
                  return (
                    <SwiperSlide className="img-slider">
                      <img
                        src={element?.MediaURL}
                        className="property-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = AmberwoodPlaceholder;
                        }}
                        alt={props.streetName}
                      />
                    </SwiperSlide>
                  );
                }
              })}
              {props?.imgList?.map((img) => {
                return (
                  <SwiperSlide className="img-slider" key={img?.MediaKey}>
                    <img
                      src={img?.MediaURL}
                      className="property-img"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = AmberwoodPlaceholder;
                      }}
                      alt={props.streetName}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className="favorites">
              <div
                className={`logo-badge ${
                  props.leaseIndicator === "Lease" ? "d-block" : "d-none"
                }`}
              >
                <p>For Rent/Lease</p>
              </div>
            </div>
          </div>
          <div className="property-details">
            <div className="top-section">
              <h2>{`${formatter.format(props.price)}`}</h2>
              <div className="d-flex align-items-center">
                <div
                  className={`badge ${
                    props.remainingDays === null || props.remainingDays === 0
                      ? "d-none"
                      : "d-block"
                  }`}
                >
                  <p>
                    {props.remainingDays} day
                    {props.remainingDays > 1 ? "s" : ""} on market
                  </p>
                </div>
                <div className="images-number">
                  <CameraIcon size="16" color="#FFF" />
                  <p>{props?.imgList?.length}</p>
                </div>
              </div>
            </div>
            <div className="middle-section">
              <div>
                <h2>{props.streetName}</h2>
                <p>{props.cityName}</p>
              </div>
            </div>
            <div className="bottom-section">
              <div className="d-flex align-items-center">
                <p>
                  Home Size: <span>{unitFormatter.format(props.area)}</span>{" "}
                  sqft
                </p>
              </div>
              <div className="other-details">
                <div className="details-box">
                  <IoIosBed className="icon" color="#096CC9" size="22" />
                  <p>{props.beds ? props.beds : 0}</p>
                </div>
                <div className="details-box">
                  <FaBath className="icon" color="#096CC9" size="20" />
                  <p>{props.baths ? props.baths : 0}</p>
                </div>
              </div>
            </div>
          </div>
        </Card.Body>
      </motion.div>
    </Link>
  );
};

export default ListingListView;
